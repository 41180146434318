import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`CoreMedia CMS manages content organized in freely configurable so-called content items. Content items normally contain
the information of one entity. They may contain only a single information, an image, for example or may merge all information concerning a content object.
Consider, for example, an article which might have a title, the actual article text, accompanying images and some more information. CoreMedia
Content Cloud comes with predefined content types for a lot of use cases.`}</p>
    <p>{`The next image shows how some of the predefined content items define the structure of your Website. The Page item defines the structure
of a page of your Website while a Collection combines some Articles (or other content) which should be shown in a specific part of the page.
Read the `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-user-en/content/ContentModel.xml.html"
      }}>{`Studio User Manual`}</a>{`
to learn more about the other content items.`}</p>
    <p><img parentName="p" {...{
        "src": "/ad1e41f08ff0a269e17fa8603ecf96f1/pageStructureSimplified_Dia.svg",
        "alt": null
      }}></img>{`
`}<em parentName="p">{`Simplified page structure of the default sites`}</em></p>
    <p>{`To have a consistent appearance and to avoid unnecessary effort, templates should be used for the creation of content items.
CoreMedia supports this in the form of content types, which can be designed following object-oriented principles.`}</p>
    <p>{`Content items in CoreMedia CMS are described by so-called content properties (also called fields or properties for short).
An item of the type "Article" for example may consist of properties such as:`}</p>
    <ul>
      <li parentName="ul">{`author`}</li>
      <li parentName="ul">{`date`}</li>
      <li parentName="ul">{`title`}</li>
      <li parentName="ul">{`summary`}</li>
      <li parentName="ul">{`textual content`}</li>
      <li parentName="ul">{`accompanying images`}</li>
    </ul>
    <p>{`On the other hand, an item of type image item has different content fields, such as dimensions, graphical data or copyright information.`}</p>
    <p>{`The more structured a content item is in specific fields, the more flexible is the access to contents from output templates,
for web page construction, for example.`}</p>
    <p>{`Since content type and source format of the content items are application-specific, CoreMedia Content Cloud content types are not
rigidly programmed. You have the possibility to design the content types and fields that you need to represent the maximum
amount of structured information from your content in CoreMedia Content Cloud. In general, this design process will be done in cooperation
with members of the editorial staff.`}</p>
    <p>{`The content types can be defined in one or several XML files, to support a more modular content type assembly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-XML"
      }}>{`  <DocType Name="CMArticle" Parent="CMTeasable">
    <LinkListProperty Name="master" Max="1" LinkType="CMArticle" Override="true" extensions:weakLink="true"/>
  </DocType>
  <DocType Name="CMTeasable" Parent="CMHasContexts" Abstract="true">
    <LinkListProperty Name="master" Max="1" LinkType="CMTeasable" Override="true" extensions:weakLink="true"/>
    <StringProperty Name="teaserTitle" Length="512" extensions:translatable="true"/>
    <XmlProperty Name="teaserText" Grammar="coremedia-richtext-1.0" extensions:translatable="true"/>
    <XmlProperty Name="detailText" Grammar="coremedia-richtext-1.0" extensions:translatable="true"/>
    <LinkListProperty Name="pictures" LinkType="CMMedia"/>
    <IntProperty Name="notSearchable"/>
    <LinkListProperty Name="related" LinkType="CMTeasable"/>
    <LinkListProperty Name="authors" LinkType="CMPerson"/>
  </DocType>
`}</code></pre>
    <p><em parentName="p">{`Example DocType definitions for CMArticle and CMTeasable`}</em></p>
    <p>{`The code example above demonstrates that CoreMedia Content Cloud has an inheritance concept for content types and properties.
In the `}{`<`}{`DocType> element, there is an optional attribute, Parent. If this attribute is set to the name of another content
type, the new content type inherits all fields of its parent content.`}</p>
    <h2>{`Properties`}</h2>
    <p>{`Each content type needs a specification of all properties that the corresponding content items should have. The properties, presented
as fields to the editor, vary, for example simple strings (such as for the author property), XML for the textual content or binary
data for graphics. This is determined in the content definition type with appropriate property types.`}</p>
    <p>{`The property types are:`}</p>
    <ul>
      <li parentName="ul">{`IntProperty`}</li>
      <li parentName="ul">{`StringProperty`}</li>
      <li parentName="ul">{`DateProperty`}</li>
      <li parentName="ul">{`XmlProperty`}</li>
      <li parentName="ul">{`BlobProperty`}</li>
      <li parentName="ul">{`LinkListProperty`}</li>
    </ul>
    <p>{`The XmlProperty field ist very versatile, because you can attach different grammars to it. Therefore, it is used in the CoreMedia system, for example, for
rich text fields but also for struct fields.`}</p>
    <h2>{`Property Editors in Studio`}</h2>
    <p>{`CoreMedia Studio contains predefined content editors for all property types. However, you can customize the property editors
to show content in the way you want.`}</p>
    <h2>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul">{`See the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/contentserver-en/content/DocumentTypes.html"
        }}>{`Content Server Manual`}</a>{`
for more details of content type definitions`}</li>
      <li parentName="ul">{`Find a list of predefined content types in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-user-en/content/contentAssetsOverview.html"
        }}>{`Studio User Manual`}</a></li>
      <li parentName="ul">{`Get a more detailled overview of how the predefined content types are used to define your site structure in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-user-en/content/ContentModel.xml.html"
        }}>{`Studio User Manual`}</a></li>
      <li parentName="ul">{`Learn how to customize Studio property editors to show the content of your content properties, in the way you want in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-developer-en/content/AddingCustomPropertyFields.html"
        }}>{`Studio Developer Manual`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      